import { createContext } from "react"

export const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  email: null,
  userName: null,
  role: null,
  token: null,
  tokenExpirationDate: null,
  login: (userId, token, tokenExpirationDate) => {},
  logout: () => {},
})
