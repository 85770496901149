import React, { useContext, useEffect, useState } from "react"
import "./VksFlip.css"
import VokabelkarteFlip from "../components/VokabelkarteFlip"
import { TopbarContext } from "../App"

const VksFlip = () => {
  const [vocs, setVocs] = useState([])
  const data = useContext(TopbarContext)
  useEffect(() => {
    if (data) {
      setVocs(data.vocs)
    }
  }, [data])

  return (
    <div className="containerVksFlip">
      {vocs.map((voc) => (
        <VokabelkarteFlip key={voc.id} voc={voc} />
      ))}
    </div>
  )
}

export default VksFlip
