import React, { useState, useContext } from "react"
// import jwt from "jsonwebtoken"
// const bcrypt = require("bcryptjs")
// const jwt = require("jsonwebtoken")

import Card from "../../shared/components/UIElements/Card"
import Input from "../../shared/components/FormElements/Input"
import Button from "../../shared/components/FormElements/Button"
import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE } from "../../shared/util/validators"
import { useForm } from "../../shared/hooks/form-hook"
import { AuthContext } from "../../shared/context/auth-context"
import "./Auth.css"
import { useNavigate } from "react-router-dom"
const Auth = () => {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const [isLoginMode, setIsLoginMode] = useState(true)

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  )

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      )
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: "",
            isValid: false,
          },
        },
        false
      )
    }
    setIsLoginMode((prevMode) => !prevMode)
  }

  const authSubmitHandler = (event) => {
    event.preventDefault()
    console.log("Hallo login/logout")
    console.log(formState.inputs)

    if (formState.inputs.email.value === "ein@hagemeier-web.de" && formState.inputs.password.value === "wert09") {
      const userId = "999" // Hier setzt du das tatsächliche Benutzer-ID ein
      // const secretKey = "SanPedroLaLaguna" // Geheime Schlüssel für die Token-Signatur

      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDNlMzE0ZDM0OTEwZjU2OTA2ZjVlOTAiLCJlbWFpbCI6ImV1QGhhZ2VtZWllci13ZWIuZGUiLCJpYXQiOjE3MDQ0MTk3MTEsImV4cCI6MTcwNDY3ODkxMX0.Q_yeL9Cc1ED_y71tM4C0doVWpshw9ePBOJFysR1qVNU"
      const tokenExpirationDate = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000) // 30 Tage Ablaufdatum

      auth.login(userId, token, tokenExpirationDate)

      // Speichere Token und Ablaufdatum im Local Storage
      localStorage.setItem("token", token)
      localStorage.setItem("tokenExpirationDate", tokenExpirationDate.toISOString())

      navigate("/")
    } else {
      // ...
      // Deine Fehlermeldung oder anderes Handling für fehlerhafte Anmeldedaten hier einfügen
      return (
        <Card className="authentication">
          <h1>Falsche Eingaben!</h1>
        </Card>
      )
    }
  }

  return (
    <Card className="authentication">
      <h2>Login Required</h2>
      <hr />
      <form onSubmit={authSubmitHandler}>
        {!isLoginMode && <Input element="input" id="name" type="text" label="Your Name" validators={[VALIDATOR_REQUIRE()]} errorText="Please enter a name." onInput={inputHandler} />}
        <Input element="input" id="email" type="email" label="E-Mail" validators={[VALIDATOR_EMAIL()]} errorText="Please enter a valid email address." onInput={inputHandler} />
        <Input element="input" id="password" type="password" label="Password" validators={[VALIDATOR_MINLENGTH(5)]} errorText="Please enter a valid password, at least 5 characters." onInput={inputHandler} />
        <Button type="submit" disabled={!formState.isValid}>
          {isLoginMode ? "LOGIN" : "SIGNUP"}
        </Button>
      </form>
      <Button inverse onClick={switchModeHandler}>
        SWITCH TO {isLoginMode ? "SIGNUP" : "LOGIN"}
      </Button>
    </Card>
  )
}

export default Auth
