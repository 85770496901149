import React, { useEffect, useContext, useState } from "react"
import "./Text.css"

import { TopbarContext } from "../App"

const Text = () => {
  const [vocs, setVocs] = useState([])
  const data = useContext(TopbarContext)
  console.log("data in Text: ", data)
  useEffect(() => {
    if (data && data.vocs.length > 0) {
      setVocs(data.vocs)
    }
  }, [data])
  console.log("vocs in Text: ", vocs)

  return (
    <div className="containertext">
      {vocs.map((voc) => (
        <React.Fragment key={voc.id}>
          <h6>{voc.beispielsatzuebersetzung}</h6>
          <h3>{voc.beispielsatz}</h3>
        </React.Fragment>
      ))}
    </div>
  )
}

export default Text
