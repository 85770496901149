import React, { useContext, useEffect, useState, useCallback } from "react"
import "./Karten.css"
import Vokabelkarte from "../components/Vokabelkarte"
import VokabelkarteBack from "../components/VokabelkarteBack"
import { TopbarContext } from "../App"
import Button from "../shared/components/FormElements/Button"

const VksRandomRev = () => {
  const [vocs, setVocs] = useState([])
  const data = useContext(TopbarContext)
  const [randomVoc, setRandomVoc] = useState(null)
  const [showBack, setShowBack] = useState(false) // Track whether to show VokabelkarteBack or not
  const [newRandomVoc, setNewRandomVoc] = useState(false) // Track if a new random Vokabelkarte is chosen
  const [selectedOption, setSelectedOption] = useState("dt-sp") // Anfangsoption

  useEffect(() => {
    if (data) {
      setVocs(data.vocs)
    }
  }, [data])

  const chooseRandomVoc = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * vocs.length)
    const randomVoc = vocs[randomIndex]
    setRandomVoc(randomVoc)
    setNewRandomVoc(true) // Signal that a new random Vokabelkarte is chosen
  }, [vocs])

  useEffect(() => {
    // This useEffect will run only once on component mount
    if (vocs.length > 0 && randomVoc === null) {
      chooseRandomVoc()
    }
  }, [vocs, randomVoc, chooseRandomVoc])

  useEffect(() => {
    if (newRandomVoc) {
      // Reset the state when a new random Vokabelkarte is chosen
      setShowBack(false)
      setNewRandomVoc(false)
    }
  }, [newRandomVoc])

  const handleNextClick = () => {
    if (showBack) {
      // If VokabelkarteBack is shown, choose a new random Vokabelkarte
      chooseRandomVoc()
    } else {
      // Show VokabelkarteBack
      setShowBack(true)
    }
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
    console.log("selectedOption: ", selectedOption)
  }
  return (
    <div className="containerDevice">
      <div className="containerRandom">
        {randomVoc && <>{showBack ? <Vokabelkarte key={randomVoc.id} voc={randomVoc} /> : <VokabelkarteBack key={randomVoc.id} voc={randomVoc} />}</>}
        <Button onClick={handleNextClick}>Next</Button>
        <div>
          <label>
            <input type="radio" value="sp-dt" checked={selectedOption === "sp-dt"} onChange={handleOptionChange} />
            Sp-De
          </label>
          <label>
            <input type="radio" value="dt-sp" checked={selectedOption === "dt-sp"} onChange={handleOptionChange} />
            De-Sp
          </label>
        </div>
      </div>
    </div>
  )
}

export default VksRandomRev
