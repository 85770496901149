import React, { useState } from "react"
import "./Vokabelkarte.css"

import { Link } from "react-router-dom"

const Vokabelkarte = ({ voc }) => {
  const [isHovered, setIsHovered] = useState(false)
  const vokabelId = voc.id

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleDeleteButtonClick = async () => {
    console.log("handleDeleteButtonClick event: ", voc.id)
    const userConfirmed = window.confirm("Sind Sie sicher, dass Sie diese Vokabel löschen möchten?")

    if (userConfirmed) {
      try {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/deleteVokabel/${vokabelId}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        })
      } catch (err) {
        // Handle errors
      }
    }
  }

  return (
    <div className="cardVokabelkarte">
      <div className="id" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {voc.ord}/{voc.id}
      </div>
      <div>
        {voc.artikel && (
          <>
            <span className="value">{voc.artikel}</span>
            <span className="separator">&nbsp;</span>
          </>
        )}
        <span className="wort">{voc.wort}</span>
      </div>
      <div className="value">
        <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {voc.beispielsatz.split("\n").map((beispielsatzSplitted, index) => (
            <React.Fragment key={index}>
              {beispielsatzSplitted}
              <br />
            </React.Fragment>
          ))}
        </span>
      </div>
      {isHovered && (
        <div className="modalVokabelkarte">
          <span className="modalVokabelkarte-content">
            {voc.uebersetzung.split("\n").map((textGesplittet, index) => (
              <React.Fragment key={index}>
                {textGesplittet}
                <br />
              </React.Fragment>
            ))}
          </span>
          <br></br>
          <span className="modalVokabelkarte-content" dangerouslySetInnerHTML={{ __html: voc.beispielsatzuebersetzung }} />
        </div>
      )}
      <div className="iconNav">
        <div className="verb-link">
          <Link to={`https://verben.woxikon.de/verbformen-spanisch/${voc.wort}.php`} target="_blank" rel="noopener noreferrer" title="Woxikon Konjugation für Verben">
            <img src={`body-text.svg`} alt="Pons" />
          </Link>
        </div>
        <div className="pons-link">
          <Link to={`https://de.pons.com/%C3%BCbersetzung/spanisch-deutsch/${voc.wort}`} target="_blank" rel="noopener noreferrer" title="Pons Übersetzung">
            <img src={`scooter.svg`} alt="Pons" />
          </Link>
        </div>
        <div className="megaphone-link">
          <Link to={`/texttospeech/${voc.beispielsatz}`} title="Sprachausgabe">
            <img src={`megaphone.svg`} alt="Megaphone" />
          </Link>
        </div>
        <div className="delete-link">
          <Link to={`#`} title="Delete">
            <img src={`DeleteTrash3.svg`} alt="Delete" onClick={handleDeleteButtonClick} />
          </Link>
        </div>

        <div className="edit-link">
          <Link to={`/editVokabel/${voc.id}`} title="Edit">
            <img src={`EditPencil-square.svg`} alt="Edit" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Vokabelkarte
