import React, { useState, useEffect } from "react"

const SelectionBoxes = () => {
  const [sprachen, setSprachen] = useState([])
  const [books, setBooks] = useState([])
  const [chapter, setChapter] = useState([])
  const [booksFiltered, setBooksFiltered] = useState([])
  const [chapterFiltered, setChapterFiltered] = useState([])
  const [spracheSelection, setSpracheSelection] = useState("")
  const [bookSelection, setBookSelection] = useState("")
  const [chapterSelection, setChapterSelection] = useState("")

  // const [options, setOptions] = useState([]) // Neue useState-Variable für options

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + "/getAllSprachen")
      .then((response) => response.json())
      .then((data) => {
        console.log("data nach fetch getAllSprachen:", data)
        // Überprüfe, ob das Array definiert ist, bevor du map() darauf anwendest
        if (Array.isArray(data)) {
          setSprachen(data.map((item) => item.sprache))
        } else {
          throw new Error("Ungültige Daten getAllSprachen")
        }
      })
      .catch((error) => console.error("Fehler beim Abrufen der Daten:", error))

    fetch(process.env.REACT_APP_BACKEND_URL + "/getAllBooks")
      .then((response) => response.json())
      .then((data) => {
        console.log("data nach fetch getAllBooks:", data)
        // Überprüfe, ob das Array definiert ist, bevor du map() darauf anwendest
        if (Array.isArray(data)) {
          setBooks(data.map((item) => item.book))
        } else {
          throw new Error("Ungültige Daten getAllBooks")
        }
      })
      .catch((error) => console.error("Fehler beim Abrufen der Daten:", error))

    fetch(process.env.REACT_APP_BACKEND_URL + "/getAllChapter")
      .then((response) => response.json())
      .then((data) => {
        console.log("data nach fetch getAllChapter:", data)
        // Überprüfe, ob das Array definiert ist, bevor du map() darauf anwendest
        if (Array.isArray(data)) {
          setChapter(data.map((item) => item.chapter))
        } else {
          throw new Error("Ungültige Daten getAllChapter")
        }
      })
      .catch((error) => console.error("Fehler beim Abrufen der Daten:", error))
  }, [])

  useEffect(() => {
    // Setze den Defaultwert für die erste Auswahlbox
    if (sprachen.length > 0) {
      setSpracheSelection("Deutsch")
    }
  }, [sprachen])

  useEffect(() => {
    // Setze den Defaultwert für die erste Auswahlbox
    if (books.length > 0) {
      setBookSelection("Verschiedene")
    }
  }, [books, spracheSelection])

  useEffect(() => {
    // Setze den Defaultwert für die erste Auswahlbox
    if (chapter.length > 0) {
      setChapterSelection("Verschiedene Engelkarten Advent")
    }
  }, [chapter])

  // Funktionen, um die Auswahl der Auswahlboxen zu aktualisieren
  const handleSpracheSelectionChange = (event) => {
    const value = event.target.value
    setSpracheSelection(value)
  }
  const handleBookSelectionChange = (event) => {
    const value = event.target.value
    setBookSelection(value)
  }
  const handleChapterSelectionChange = (event) => {
    const value = event.target.value
    setChapterSelection(value)
  }

  // if (sprachen === null || books === null || chapter === null) {
  //   // Loading indicator or placeholder content
  //   return <div>Loading...</div>
  // }

  return (
    <div>
      <select value={spracheSelection} onChange={handleSpracheSelectionChange}>
        {sprachen.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <select value={bookSelection} onChange={handleBookSelectionChange}>
        {books
          .filter((option) => option.sprache === spracheSelection) // Filter nach ausgewählter Sprache
          .map((option) => (
            <option key={option.book} value={option.book}>
              {option.book}
            </option>
          ))}
      </select>
      <select value={chapterSelection} onChange={handleChapterSelectionChange}>
        {chapter
          .filter((option) => option.book === bookSelection) // Filter nach ausgewählter Sprache
          .map((option) => (
            <option key={option.chapter} value={option.chapter}>
              {option.chapter}
            </option>
          ))}
      </select>
    </div>
  )
}

export default SelectionBoxes
