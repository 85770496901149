import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Input from "../shared/components/FormElements/Input"
import Button from "../shared/components/FormElements/Button"
import ErrorModal from "../shared/components/UIElements/ErrorModal"
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner"
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH } from "../shared/util/validators"
import { useForm } from "../shared/hooks/form-hook"
import { useHttpClient } from "../shared/hooks/http-hook"
import "./EditVokabel.css"

const EditVokabel = () => {
  const vokabelId = useParams().vokabelId
  const [vokabel, setVokabel] = useState()
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const navigate = useNavigate()

  const [formState, inputHandler, setFormData] = useForm(
    {
      artikel: {
        value: "",
        isValid: true,
      },
      wort: {
        value: "",
        isValid: true,
      },
      uebersetzung: {
        value: "",
        isValid: true,
      },
      beispielsatz: {
        value: "",
        isValid: true,
      },
      beispielsatzuebersetzung: {
        value: "",
        isValid: true,
      },
      sprache: {
        value: "",
        isValid: true,
      },
      book: {
        value: "",
        isValid: true,
      },
      chapter: {
        value: "",
        isValid: true,
      },
      ord: {
        value: "",
        isValid: true,
      },
    },
    false
  )

  useEffect(() => {
    const fetchVokabelById = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/getVocById/${vokabelId}`)

        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        const data = await response.json()
        setVokabel(data)
        setFormData(
          {
            artikel: {
              value: data[0].artikel,
              isValid: true,
            },
            wort: {
              value: data[0].wort,
              isValid: true,
            },
            uebersetzung: {
              value: data[0].uebersetzung,
              isValid: true,
            },
            beispielsatz: {
              value: data[0].beispielsatz,
              isValid: true,
            },
            beispielsatzuebersetzung: {
              value: data[0].beispielsatzuebersetzung,
              isValid: true,
            },
            sprache: {
              value: data[0].sprache,
              isValid: true,
            },
            book: {
              value: data[0].book,
              isValid: true,
            },
            chapter: {
              value: data[0].chapter,
              isValid: true,
            },
            ord: {
              value: data[0].ord,
              isValid: true,
            },
          },
          true
        )
      } catch (error) {
        console.error("Error fetching vokabel:", error)
      }
    }
    console.log("fetchVokabelById erreicht.")
    fetchVokabelById()
  }, [vokabelId, setFormData])

  const vokabelSubmitHandler = async (event, actionType) => {
    event.preventDefault()
    console.log("formState.inputs: ", formState.inputs)

    try {
      if (actionType === "add") {
        // Add button action
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/addVokabel`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            artikel: formState.inputs.artikel.value,
            wort: formState.inputs.wort.value,
            uebersetzung: formState.inputs.uebersetzung.value,
            beispielsatz: sonderzeichenErsetzen(formState.inputs.beispielsatz.value),
            beispielsatzuebersetzung: formState.inputs.beispielsatzuebersetzung.value,
            sprache: formState.inputs.sprache.value,
            book: formState.inputs.book.value,
            chapter: formState.inputs.chapter.value,
            ord: formState.inputs.ord.value,
          }),
        })
      } else if (actionType === "update") {
        // Update button action
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/updateVokabel/${vokabelId}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            artikel: formState.inputs.artikel.value,
            wort: formState.inputs.wort.value,
            uebersetzung: formState.inputs.uebersetzung.value,
            beispielsatz: sonderzeichenErsetzen(formState.inputs.beispielsatz.value),
            beispielsatzuebersetzung: formState.inputs.beispielsatzuebersetzung.value,
            sprache: formState.inputs.sprache.value,
            book: formState.inputs.book.value,
            chapter: formState.inputs.chapter.value,
            ord: formState.inputs.ord.value,
          }),
        })
        navigate(-1, { stateUpdate: { update: true } })
      }
    } catch (err) {
      // Handle errors
    }
  }

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    )
  }

  if (!vokabel && !error) {
    return (
      <div className="center">
        {/* <h2>Could not find place!</h2> */}
        <LoadingSpinner />
      </div>
    )
  }

  if (error) {
    return (
      <div className="center">
        <h2>Error: {error}</h2>
      </div>
    )
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && vokabel && (
        <form className="edit-form" onSubmit={vokabelSubmitHandler}>
          <Input
            id="artikel"
            element="input"
            type="text"
            label="Artikel"
            validators={[]}
            errorText="Please enter a valid artikel."
            onInput={inputHandler}
            initialValue={formState.inputs.artikel.value}
            initialValid={formState.inputs.artikel.isValid}
          />
          <Input
            id="wort"
            element="input"
            type="text"
            label="Wort"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid wort."
            onInput={inputHandler}
            initialValue={formState.inputs.wort.value}
            initialValid={formState.inputs.wort.isValid}
          />
          <Input
            id="uebersetzung"
            element="textarea"
            type="text"
            label="Übersetzung"
            rows="4"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid uebersetzung."
            onInput={inputHandler}
            initialValue={formState.inputs.uebersetzung.value}
            initialValid={formState.inputs.uebersetzung.isValid}
          />
          <Input
            id="beispielsatz"
            element="textarea"
            label="Beispielsatz"
            rows="7"
            validators={[]}
            errorText="Please enter a valid beispielsatz (min. 5 characters)."
            onInput={inputHandler}
            initialValue={formState.inputs.beispielsatz.value}
            initialValid={formState.inputs.beispielsatz.isValid}
          />
          <div>
            <Button type="submit" disabled={!formState.isValid} onClick={(event) => vokabelSubmitHandler(event, "update")}>
              Save Vokabel
            </Button>

            <Button blue type="button" disabled={!formState.isValid} onClick={(event) => vokabelSubmitHandler(event, "add")}>
              Add Vokabel
            </Button>
          </div>
          <Input
            id="beispielsatzuebersetzung"
            element="textarea"
            label="Beispielsatzübersetzung"
            rows="7"
            validators={[]}
            errorText="Please enter a valid beispielsatzuebersetzung (min. 5 characters)."
            onInput={inputHandler}
            initialValue={formState.inputs.beispielsatzuebersetzung.value}
            initialValid={formState.inputs.beispielsatzuebersetzung.isValid}
          />
          <Input id="ord" element="input" type="text" label="Ordnung" validators={[]} errorText="Ordnung braucht keine Zeichen." onInput={inputHandler} initialValue={formState.inputs.ord.value} initialValid={formState.inputs.ord.isValid} />
          <Button type="submit" disabled={!formState.isValid} onClick={(event) => vokabelSubmitHandler(event, "update")}>
            Save Vokabel
          </Button>

          <Input
            id="sprache"
            element="input"
            type="text"
            label="Sprache"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Please enter a valid sprache (min. 5 characters)."
            onInput={inputHandler}
            initialValue={formState.inputs.sprache.value}
            initialValid={formState.inputs.sprache.isValid}
          />
          <Input
            id="book"
            element="input"
            type="text"
            label="Book"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Please enter a valid sprache (min. 5 characters)."
            onInput={inputHandler}
            initialValue={formState.inputs.book.value}
            initialValid={formState.inputs.book.isValid}
          />
          <Input
            id="chapter"
            element="input"
            type="text"
            label="Chapter"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Please enter a valid sprache (min. 5 characters)."
            onInput={inputHandler}
            initialValue={formState.inputs.chapter.value}
            initialValid={formState.inputs.chapter.isValid}
          />
        </form>
      )}
    </React.Fragment>
  )
}

function sonderzeichenErsetzen(string) {
  string = string.replaceAll(" , ", ", ")
  string = string.replaceAll(" ; ", "; ")
  string = string.replaceAll(" .", ".")
  string = string.replaceAll(" !", "!")
  string = string.replaceAll(" ?", "?")
  string = string.replaceAll("...", " ...")
  string = string.replaceAll("nnn", "ñ") // Alt 164
  string = string.replaceAll("NNN", "Ñ") // Alt 165
  string = string.replaceAll("??", "¿") // Alt 168
  string = string.replaceAll("!!", "¡") // Alt 173
  // vgl: https://spanisch.de/grammatik/sonderzeichen/
  return string
}

export default EditVokabel
