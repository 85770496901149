import React, { useState } from "react"
import "./Vokabelkarte.css"
import { Link } from "react-router-dom"

const Vokabelkarte = ({ voc }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div className="cardVokabelkarte">
      <div className="line">
        <div className="id" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {voc.id}
        </div>
      </div>

      <div className="line">
        <span className="wort">
          {voc.uebersetzung.split("\n").map((text, index) => (
            <React.Fragment key={index}>
              {text}
              <br />
            </React.Fragment>
          ))}
        </span>
      </div>
      <div className="line">
        <span className="value" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {voc.beispielsatzuebersetzung}
        </span>
      </div>
      {isHovered && (
        <div className="modalVokabelkarte">
          <p className="modalVokabelkarte-content">
            {voc.artikel} {voc.wort}
          </p>
          <span className="modalVokabelkarte-content">{voc.beispielsatz}</span>
        </div>
      )}
      <div className="iconNav">
        <div className="megaphone-link">
          <Link to={`/texttospeech/${voc.beispielsatz}`} title="Sprachausgabe">
            <img src={`megaphone.svg`} alt="Megaphone" />
          </Link>
        </div>
        <div className="edit-link">
          <Link to={`/editVokabel/${voc.id}`}>
            <img src={`EditPencil-square.svg`} alt="Edit" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Vokabelkarte
