import React, { useState, useEffect, useCallback } from "react"
import { useParams, useNavigate } from "react-router-dom"
import "./TextToSpeech.css"
import Button from "../shared/components/FormElements/Button"

const TextToSpeech = () => {
  const text = useParams().text
  const navigate = useNavigate()
  const [isPaused, setIsPaused] = useState(false)
  const [utterance, setUtterance] = useState(null)
  const [voice, setVoice] = useState(null)
  const [pitch, setPitch] = useState(1)
  const [rate, setRate] = useState(1)
  const [volume, setVolume] = useState(1)

  console.log("TextToSpeecherreicht: ", text)

  useEffect(() => {
    const synth = window.speechSynthesis
    const u = new SpeechSynthesisUtterance(text)
    const voices = synth.getVoices()

    setUtterance(u)
    setVoice(voices[0])

    return () => {
      synth.cancel()
    }
  }, [text])

  // Füge das useEffect-Hook hinzu, um die Voreinstellung für die Stimme zu setzen
  useEffect(() => {
    const voices = window.speechSynthesis.getVoices()
    const defaultVoice = voices.find((v) => v.name === "Google español")
    if (defaultVoice) {
      setVoice(defaultVoice)
    }
  }, [])

  const handlePlay = useCallback(() => {
    const synth = window.speechSynthesis

    if (isPaused) {
      synth.resume()
    } else if (utterance) {
      // Überprüfe, ob utterance nicht null ist, bevor du die Eigenschaften setzt
      utterance.voice = voice
      utterance.pitch = pitch
      utterance.rate = rate
      utterance.volume = volume
      synth.speak(utterance)
    }

    setIsPaused(false)
  }, [isPaused, voice, pitch, rate, volume, utterance])

  useEffect(() => {
    handlePlay() // Rufe die handlePlay-Funktion auf, um das Abspielen zu starten
  }, [handlePlay])

  const handlePause = () => {
    const synth = window.speechSynthesis

    synth.pause()

    setIsPaused(true)
  }

  const handleStop = () => {
    const synth = window.speechSynthesis

    synth.cancel()

    setIsPaused(false)
  }

  const handleVoiceChange = (event) => {
    const voices = window.speechSynthesis.getVoices()
    setVoice(voices.find((v) => v.name === event.target.value))
  }

  const handlePitchChange = (event) => {
    setPitch(parseFloat(event.target.value))
  }

  const handleRateChange = (event) => {
    setRate(parseFloat(event.target.value))
  }

  const handleVolumeChange = (event) => {
    setVolume(parseFloat(event.target.value))
  }

  const handleGoBack = () => {
    navigate(-1) // Zurück zur vorherigen Seite
  }

  return (
    <div className="containerTextToSpeech">
      <h3>
        Text:
        {text}
      </h3>
      <br></br>
      <label>
        Voice:
        <select value={voice?.name} onChange={handleVoiceChange}>
          {window.speechSynthesis.getVoices().map((voice) => (
            <option key={voice.name} value={voice.name}>
              {voice.name}
            </option>
          ))}
        </select>
      </label>

      <br />
      <br />
      <br />

      <label>
        Pitch:
        <input type="range" min="0.5" max="2" step="0.1" value={pitch} onChange={handlePitchChange} />
      </label>

      <br />
      <br />

      <label>
        Speed:
        <input type="range" min="0.5" max="2" step="0.1" value={rate} onChange={handleRateChange} />
      </label>
      <br />
      <br />
      <label>
        Volume:
        <input type="range" min="0" max="1" step="0.1" value={volume} onChange={handleVolumeChange} />
      </label>

      <br />
      <br />

      <button onClick={handlePlay}>{isPaused ? "Resume" : "Play"}</button>
      <button onClick={handlePause}>Pause</button>
      <button onClick={handleStop}>Stop</button>
      <br />
      <Button blue type="submit" onClick={handlePlay}>
        {isPaused ? "Resume" : "Play"}
      </Button>
      <br />

      <button onClick={handleGoBack}>Zurück zur vorherigen Seite</button>
    </div>
  )
}

export default TextToSpeech
