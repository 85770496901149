import React from "react"
import "./VokabelkarteFlip.css"

const VokabelkarteFlip = ({ voc }) => {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <div className="line">
            {voc.artikel && (
              <>
                <span className="value">{voc.artikel}</span>
                <span className="separator">&nbsp;</span>
              </>
            )}
            <span className="wort">{voc.wort}</span>
          </div>
          <div className="line">
            <span className="wort">{voc.beispielsatz}</span>
          </div>
        </div>
        <div className="flip-card-back">
          <div className="line">
            <span className="wort">{voc.uebersetzung}</span>
          </div>
          <div className="line">
            <span className="wort">{voc.beispielsatzuebersetzung}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VokabelkarteFlip
