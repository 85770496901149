import React, { useContext, useEffect, useState, useCallback } from "react"
import "./VksRandomDev.css"
import Vokabelkarte from "../components/Vokabelkarte"
import VokabelkarteBack from "../components/VokabelkarteBack"
import { TopbarContext } from "../App"
import Button from "../shared/components/FormElements/Button"

const VksRandomDev = () => {
  const [vocs, setVocs] = useState([])
  const data = useContext(TopbarContext)
  const [randomVoc, setRandomVoc] = useState(null)
  const [selectedOption, setSelectedOption] = useState("dt-sp") // Anfangsoption
  const [key, setKey] = useState(0) // Neuer Zustand für das Neuzeichnen der Komponente

  useEffect(() => {
    if (data) {
      setVocs(data.vocs)
    }
  }, [data])

  const chooseRandomVoc = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * vocs.length)
    const randomVocNeu = vocs[randomIndex]
    setRandomVoc(randomVocNeu)
  }, [vocs])

  useEffect(() => {
    // This useEffect will run only once on component mount
    chooseRandomVoc()
  }, [vocs])

  const handleNextClick = () => {
    chooseRandomVoc()
    setKey((prevKey) => prevKey + 1) // Ändert den Schlüsselwert, um die Komponente neu zu rendern
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  return (
    <div className="wrapperGrid" key={key}>
      {" "}
      {/* Verwende den Schlüssel hier */}
      {randomVoc && <Vokabelkarte key={randomVoc.id} voc={randomVoc} />}
      {randomVoc && <VokabelkarteBack key={randomVoc.id} voc={randomVoc} />}
      <div>
        <label>
          <input type="radio" value="sp-dt" checked={selectedOption === "sp-dt"} onChange={handleOptionChange} />
          Sp-De
        </label>
        <label>
          <input type="radio" value="dt-sp" checked={selectedOption === "dt-sp"} onChange={handleOptionChange} />
          De-Sp
        </label>
      </div>
      <Button onClick={handleNextClick}>Next</Button>
    </div>
  )
}

export default VksRandomDev
