import React, { useEffect, useContext, useState } from "react"
// import axios from "axios"
import "./VocsList.css" // Importieren Sie Ihre CSS-Datei

import { TopbarContext } from "../App"

const VocsList = () => {
  const [vocs, setVocs] = useState([])
  const data = useContext(TopbarContext)
  const [hoveredVoc, setHoveredVoc] = useState(null) // Zustand für den gehoverten voc
  useEffect(() => {
    if (data && data.vocs.length > 0) {
      setVocs(data.vocs)
    }
  }, [data])

  const handleVocHover = (vocId) => {
    setHoveredVoc(vocId)
  }

  const handleVocLeave = () => {
    setHoveredVoc(null)
  }

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" }
    return new Date(dateString).toLocaleDateString("de-DE", options)
  }

  return (
    <div>
      <h1>Vocs List</h1>
      <ul className="voc-list">
        {vocs.map((voc) => (
          <li key={voc.id} onMouseEnter={() => handleVocHover(voc.id)} onMouseLeave={handleVocLeave} className="voc-item">
            <span>{voc.beispielsatz}</span>
            <span>
              {voc.artikel} <span className="voc-word">{voc.wort}</span> {formatDate(voc.datum)}
            </span>
            {hoveredVoc === voc.id ? (
              <p>
                <span className="voc-translation">{voc.uebersetzung}</span>
              </p>
            ) : (
              <br></br>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default VocsList
