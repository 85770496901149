// App.js

import React, { createContext, useEffect, useState, useCallback } from "react"
import "./App.css"
import Navbar from "./Navbar"
import Home from "./pages/Home"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import VocsList from "./pages/VocsList"
import Karten from "./pages/Karten"
import KartenRev from "./pages/KartenRev"
import VksRandom from "./pages/VksRandom"
import VksRandomRev from "./pages/VksRandomRev"
import VksRandomDev from "./pages/VksRandomDev"
import Text from "./pages/Text"
import Selectionbox from "./pages/Selectionbox"
import VksFlip from "./pages/VksFlip"
import VksGroß from "./pages/VksGroß"
import VksKlein from "./pages/VksKlein"
import EditVokabel from "./pages/EditVokabel"
import Auth from "./user/pages/Auth"
import { AuthContext } from "./shared/context/auth-context"
import TextToSpeech from "./pages/TextToSpeech"

export const TopbarContext = createContext(null)

function App() {
  const [vocs, setVocs] = useState(null)
  const updateVocs = (newVocs) => {
    setVocs(newVocs)
  }
  const [choosenChapter, setChoosenChapter] = useState(encodeURIComponent("SanPedro01"))
  const updateChoosenChapter = (newChoosenChapter) => {
    setChoosenChapter(newChoosenChapter)
  }
  // isLoggedIn isLoggedIn isLoggedIn isLoggedIn isLoggedIn isLoggedIn isLoggedIn
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userId, setUserId] = useState(null) // Add this line
  const [email, setEmail] = useState(null) // Add this line
  const [userName, setUserName] = useState(null) // Add this line
  const [role, setRole] = useState(null) // Add this line
  const [token, setToken] = useState(null)
  const [tokenExpirationDate, setTokenExpirationDate] = useState(null)

  useEffect(() => {
    const storedToken = localStorage.getItem("token")
    const storedTokenExpirationDate = localStorage.getItem("tokenExpirationDate")

    if (storedToken && storedTokenExpirationDate) {
      const expirationDate = new Date(storedTokenExpirationDate)
      if (expirationDate > new Date()) {
        setIsLoggedIn(true)
        setToken(storedToken)
        setTokenExpirationDate(expirationDate)
      }
    }
  }, [])

  const login = useCallback((userId, token, tokenExpirationDate) => {
    setIsLoggedIn(true)
    setUserId(userId) // Add this line
    // Set other user data like email, userName, role if available
    setToken(token)
    setTokenExpirationDate(tokenExpirationDate)
  }, [])

  const logout = useCallback(() => {
    setIsLoggedIn(false)
    setUserId(null) // Add this line
    setEmail(null) // Add this line
    setUserName(null) // Add this line
    setRole(null) // Add this line
    setToken(null)
    setTokenExpirationDate(null)
    localStorage.removeItem("token")
    localStorage.removeItem("tokenExpirationDate")
  }, [])

  useEffect(() => {
    const fetchVocs = async () => {
      try {
        const search = choosenChapter
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/vocsbychapter?search=" + search + "&sort='ord'")
        const data = await response.json()
        // Sortiere die Daten nach "ord" Todo: Weil die Abfrage mit sort noch nicht funktioniert!!!
        const sortedData = data.sort((a, b) => a.ord - b.ord)

        setVocs(sortedData)
        console.log("data in App: ", sortedData)
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error)
      }
    }

    fetchVocs()
  }, [choosenChapter])

  let routes

  if (isLoggedIn) {
    routes = (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/vokabelliste" element={<VocsList />} />
        <Route path="/karten" element={<Karten />} />
        <Route path="/kartenrev" element={<KartenRev />} />
        <Route path="/vksrandom" element={<VksRandom />} />
        <Route path="/vksrandomrev" element={<VksRandomRev />} />
        <Route path="/vksrandomdev" element={<VksRandomDev />} />
        <Route path="/text" element={<Text />} />
        <Route path="/selectionbox" element={<Selectionbox />} />
        <Route path="/vksflip" element={<VksFlip />} />
        <Route path="/vksgroß" element={<VksGroß />} />
        <Route path="/vksklein" element={<VksKlein />} />
        <Route path="/editvokabel/:vokabelId" element={<EditVokabel />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/texttospeech/:text" element={<TextToSpeech />} />
      </Routes>
    )
  } else {
    routes = (
      <Routes>
        <Route path="/auth" element={<Auth />} />
      </Routes>
    )
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, userId, email, userName, role, token, tokenExpirationDate, login, logout }}>
      <Router>
        <TopbarContext.Provider value={{ vocs, updateVocs, choosenChapter, updateChoosenChapter }}>
          <Navbar />
          <main>{routes}</main>
        </TopbarContext.Provider>
      </Router>
    </AuthContext.Provider>
  )
}

export default App
