import React, { useState, useContext, useEffect, useCallback, useRef } from "react"
import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom"
import "./Navbar.css"
import { TopbarContext } from "./App"
import { AuthContext } from "./shared/context/auth-context"
// import ButtonTopbar from "./shared/components/FormElements/ButtonTopbar"

export default function Navbar() {
  const auth = useContext(AuthContext)
  const [searchString, setSearchString] = useState("")
  // console.log("Navbar wurde aufgerufen!")
  const { updateVocs, choosenChapter, updateChoosenChapter } = useContext(TopbarContext)
  const [selectedItem, setSelectedItem] = useState(decodeURIComponent(choosenChapter))

  const [chapterInOption, setChapterInOption] = useState([])
  const [labelInOption, setlabelInOption] = useState([])
  const [selectedValueSort, setSelectedValueSort] = useState("ord")
  const [sortString, setSortString] = useState("ord") // Initial value set

  const prevSortString = useRef(sortString)
  const location = useLocation()

  // let sortString = ""
  const book = "LaCiudadDeLasBestias"

  useEffect(() => {
    const fetchChapterByBook = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getchapterbybook/${book}`)
        const data = await response.json()
        const sortedChapters = [...data].sort((a, b) => b.chapter.localeCompare(a.chapter))
        setChapterInOption(sortedChapters)
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten in fetchChapterByBook.", error)
      }
    }
    fetchChapterByBook()
  }, [book])

  // Woher und wann weiß ich, das chapterInOption geladen ist?

  const fetchData = useCallback(
    async (searchChapter, sortString) => {
      // Achtung, die erste Abfrage findet zu Intialisierung nicht hier, sondern in App.js statt. Das ist nicht optimal und muss bei Änderungen berücksichtigt werden.
      try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/vocsbychapter?search=" + searchChapter + "&sort=" + sortString)
        const data = await response.json()
        updateVocs(data)
        console.log("Data geordnet: ", data)
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error)
      }
    },
    [updateVocs]
  )

  useEffect(() => {
    const createLabelInOption = () => {
      if (chapterInOption.length > 0) {
        const optionTexte = chapterInOption.map((item) => {
          const words = item.chapter.split(" ")
          const optionText = (words[1] || "") + " " + (words[2] || "") + " " + (words[3] || "")
          return {
            optionText,
          }
        })
        setlabelInOption(optionTexte)
      }
    }

    createLabelInOption()
  }, [chapterInOption])

  // Todo: location.stateUpdate wegen navigate -1 funktioniert nicht
  useEffect(() => {
    console.log("sortString wurde gesetzt: ", sortString)
    const searchChapter = encodeURIComponent(selectedItem)

    // Check if sortString has changed
    if (sortString !== prevSortString.current || (location.stateUpdate && location.stateUpdate.update)) {
      prevSortString.current = sortString
      fetchData(searchChapter, sortString)
    }
  }, [fetchData, selectedItem, sortString, location.stateUpdate])

  const fetchDataWithSearchString = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/vocsbysearchstring/${searchString}`)
      const data = await response.json()
      updateVocs(data)
    } catch (error) {
      console.error("Fehler beim Abrufen der Daten in fetchDataWithSearchString.", error)
    }
  }

  const handleSelectionChapter = (event) => {
    const selectedValue = event.target.value
    setSelectedItem(selectedValue)
    if (selectedValue !== "") {
      const searchChapter = encodeURIComponent(selectedValue)
      fetchData(searchChapter, sortString)
    }
    updateChoosenChapter(selectedValue)
  }

  const handleSearchStringChange = (event) => {
    const { value } = event.target
    setSearchString(value)
  }

  const handleFetchButtonClick = () => {
    if (searchString) {
      fetchDataWithSearchString(searchString)
    } else {
      if (selectedItem !== "") {
        const searchChapter = encodeURIComponent(selectedItem)
        fetchData(searchChapter, sortString)
      }
    }
    console.log("handleFetchButtonClick: fertig: selectedItem: ", selectedItem)
  }

  const handleRadioSort = (event) => {
    const selectedSortOption = event.target.value
    setSelectedValueSort(selectedSortOption)

    switch (selectedSortOption) {
      case "alph":
        setSortString("wort")
        break
      case "ord":
        setSortString("ord")
        break
      case "bsps":
        setSortString("beispielsatz")
        break
      case "id":
        setSortString("id")
        break
      // Beachte oben (Z17/18)
      // const [selectedValueSort, setSelectedValueSort] = useState("ord")
      // const [sortString, setSortString] = useState("ord") // Initial value set
      default:
        setSortString("ord")
    }

    // console.log("sortString wurde gesetzt: ", sortString)
    // const searchChapter = encodeURIComponent(selectedItem)
    // fetchData(searchChapter, sortString) // Use the updated sortString
  }

  if (chapterInOption === null || chapterInOption.length < 3 || labelInOption === null || labelInOption.length < 3) {
    // Loading indicator or placeholder content
    return <div>Loading...</div>
  }

  return (
    <div>
      {false ? (
        <p>Loading...</p>
      ) : (
        <nav className="nav">
          <div className=".nav-group">
            {auth.isLoggedIn && (
              <div className="nav-group">
                <CustomLink to="/vokabelliste">Liste</CustomLink>
                <CustomLink to="/karten">Kt</CustomLink>
                <CustomLink to="/kartenrev">KtRev</CustomLink>
                <CustomLink to="/vksrandom">Rd</CustomLink>
                <CustomLink to="/vksrandomrev">RdRev</CustomLink>
                <CustomLink to="/vksrandomdev">RdDev</CustomLink>
                <CustomLink to="/vksflip">Flip</CustomLink>
                <CustomLink to="/text">Text</CustomLink>
                <CustomLink to="/selectionbox">SelBox</CustomLink>
                <CustomLink to="/vksgroß">VksGroß</CustomLink>
                <CustomLink to="/vksklein">VksKlein</CustomLink>
                <button className="logout-button" onClick={auth.logout}>
                  Logout
                </button>
                <label>{decodeURIComponent(`${choosenChapter}`)}</label>
              </div>
            )}
            {!auth.isLoggedIn && (
              <div>
                <CustomLink to="/auth">Auth</CustomLink>
              </div>
            )}
          </div>
          {/* </ul> */}
          <div className="nav-group-right">
            <div className="site-title">
              <Link to="/">EckisVokabeln</Link>
            </div>

            <label>
              <input type="radio" value="alph" checked={selectedValueSort === "alph"} onChange={handleRadioSort} />
              alph
            </label>
            <label>
              <input type="radio" value="ord" checked={selectedValueSort === "ord"} onChange={handleRadioSort} />
              ord
            </label>
            <label>
              <input type="radio" value="bsps" checked={selectedValueSort === "bsps"} onChange={handleRadioSort} />
              bsps
            </label>
            <label>
              <input type="radio" value="id" checked={selectedValueSort === "id"} onChange={handleRadioSort} />
              id
            </label>

            <select value={selectedItem} onChange={handleSelectionChapter}>
              <option value="">Bitte wählen</option>
              <option value="SanPedro01">SanPedro 01</option>
              <option value="SanPedroVerben">SanPedro Verben</option>
              <option value="SanPedroMensajes">SanPedro Mensajes</option>
              <option value="SanPedro02">SanPedro 02</option>
              <option value="SanPedro03">SanPedro 03</option>
              <option value="01 01 Part I It was a pleasure to burn">Fahrenheit451 01</option>
              <option value="01 02 Part I His wife stretched on the bed">Fahrenheit451 02</option>
              <option value="02 03 Part II The parlour was dead">Fahrenheit451 03</option>
              <option value="03 04 Part III Now, sucking all">Fahrenheit451 04</option>
              <option value="Wortschatz01 Eleven Minutes">Eleven Minutes</option>
              <option value="Hueber  auffrischen 01 Grammatik">HueberGram01</option>
              <option value="Hueber  auffrischen 02 Grammatik">HueberGram02</option>
              <option value="Hueber  auffrischen 03 Grammatik">HueberGram03</option>
              <option value="Hueber  auffrischen 04 Grammatik">HueberGram04</option>
              <option value="01. Bis fünfzig">Caminos 1-50</option>
              <option value="02. Bis hundert">Caminos 51-100</option>
              <option value="03. Bis einhundertfünfzig">Caminos 101-150</option>
              <option value="04. Bis zweihundert">Caminos 151-200</option>
              <option value="05. Bis zweihundertfünfzig">Caminos 201-251</option>
              <option value="01 01 El Valle de los Yetis">01 El Valle</option>
              <option value="01 02 Tres huevos fabulosos">02 Tres huevos</option>
              <option value="01 03 El Coleccionista">03 El Coleccionista</option>

              {chapterInOption.map((chapter, index) => (
                <option key={index} value={chapter.chapter}>
                  {labelInOption[index].optionText}
                </option>
              ))}
            </select>

            {/* ToDo Input verwenden mit Validator */}
            <input className="searchStringField" type="text" value={searchString} onChange={handleSearchStringChange} />
            <button onClick={handleFetchButtonClick}>Fetch</button>
          </div>
        </nav>
      )}
    </div>
  )

  // return (

  // )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <div className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </div>
  )
}
