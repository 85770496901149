import React, { useContext, useEffect, useState } from "react"
import "./Karten.css"
import Vokabelkarte from "../components/Vokabelkarte"
import { TopbarContext } from "../App"

const Karten = () => {
  const [vocs, setVocs] = useState([])
  const data = useContext(TopbarContext)
  useEffect(() => {
    if (data) {
      setVocs(data.vocs)
    }
  }, [data])

  return (
    <div className="containerKarten">
      {vocs.map((voc) => (
        <Vokabelkarte key={voc.id} voc={voc} />
      ))}
    </div>
  )
}

export default Karten
