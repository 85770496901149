import React, { useContext, useEffect, useState } from "react"
import "./Karten.css"
import VokabelkarteBack from "../components/VokabelkarteBack"
import { TopbarContext } from "../App"

const KartenRev = () => {
  const [vocs, setVocs] = useState([])
  const data = useContext(TopbarContext)
  useEffect(() => {
    if (data) {
      setVocs(data.vocs)
    }
  }, [data])

  return (
    <div className="containerKarten">
      {vocs.map((voc) => (
        <VokabelkarteBack key={voc.id} voc={voc} />
      ))}
    </div>
  )
}

export default KartenRev
