import React, { useContext, useEffect, useState } from "react"
import jsPDF from "jspdf"
import { TopbarContext } from "../App"

const VksGroß = () => {
  const [vocs, setVocs] = useState([])
  const data = useContext(TopbarContext)
  useEffect(() => {
    if (data) {
      setVocs(data.vocs)
    }
  }, [data])

  const generatePDF = async () => {
    const doc = new jsPDF()
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()
    const boxWidth = pageWidth / 2
    const boxHeight = pageHeight / 4
    const itemsPerPage = 8

    const totalPages = Math.ceil(vocs.length / itemsPerPage) // Gesamtzahl der Seiten

    let boxesFront = [] // Kästen für Vorderseiten

    for (let page = 0; page < totalPages; page++) {
      const startIndex = page * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      const pageVocs = vocs.slice(startIndex, endIndex)

      boxesFront = pageVocs.map((voc) => {
        if (voc.artikel) {
          return `${voc.id}\n${voc.artikel} ${voc.wort}\n${voc.beispielsatz}`
        } else {
          return `${voc.id}\n${voc.wort}\n${voc.beispielsatz}`
        }
      })

      // Vorderseite der Kästen
      boxesFront.forEach((box, index) => {
        const x = (index % 2) * boxWidth
        const y = Math.floor(index / 2) * boxHeight

        doc.rect(x, y, boxWidth, boxHeight)
        const textLines = doc.splitTextToSize(box, 90)

        doc.setFont("helvetica", "bold")
        doc.setFontSize(12)

        textLines.forEach((line, i) => {
          doc.text(line, x + 5, y + 10 + i * 5)
        })
      })

      doc.addPage() // Neue Seite für die Rückseiten

      const boxesBack = pageVocs.map((voc) => `${voc.id}\n${voc.uebersetzung}\n${voc.beispielsatzuebersetzung}`)
      boxesBack.forEach((box, index) => {
        const x = (index % 2) * boxWidth
        const y = Math.floor(index / 2) * boxHeight

        const reverseIndex = index % 2 === 0 ? index + 1 : index - 1
        const reverseBox = boxesBack[reverseIndex]

        doc.setPage(page * 2 + 2) // Wechsel zur nächsten Seite
        doc.rect(x, y, boxWidth, boxHeight)
        const textLines = doc.splitTextToSize(reverseBox, 90)

        doc.setFont("helvetica", "normal")
        doc.setFontSize(12)

        textLines.forEach((line, i) => {
          doc.text(line, x + 5, y + 10 + i * 5)
        })
      })

      if (page < totalPages - 1) {
        doc.addPage() // Zusätzliche Seite für den nächsten Durchlauf
      }
    }

    doc.save("VksGroß.pdf")
  }

  return (
    <div>
      <button onClick={generatePDF}>PDF generieren</button>
    </div>
  )
}

export default VksGroß
