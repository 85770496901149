import React, { useContext, useEffect, useState } from "react"
import jsPDF from "jspdf"
import { TopbarContext } from "../App"

const VksKlein = () => {
  const [vocs, setVocs] = useState([])
  const data = useContext(TopbarContext)
  useEffect(() => {
    if (data) {
      setVocs(data.vocs)
    }
  }, [data])

  const generatePDF = async () => {
    const doc = new jsPDF()
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()
    const boxWidth = pageWidth / 3
    const boxHeight = pageHeight / 6
    const itemsPerPage = 18

    const totalPages = Math.ceil(vocs.length / itemsPerPage) // Gesamtzahl der Seiten

    let boxesFront = [] // Kästen für Vorderseiten

    for (let page = 0; page < totalPages; page++) {
      const startIndex = page * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      const pageVocs = vocs.slice(startIndex, endIndex)

      boxesFront = pageVocs.map((voc) => {
        // let boxContent = `${voc.id}\n`
        let boxContent = ""
        if (voc.artikel) {
          boxContent += `${voc.artikel} ${voc.wort}\n`
        } else {
          boxContent += `${voc.wort}\n`
        }

        boxContent += voc.beispielsatz

        return boxContent
      })

      // Vorderseite der Kästen
      boxesFront.forEach((box, index) => {
        const x = (index % 3) * boxWidth
        const y = Math.floor(index / 3) * boxHeight

        doc.rect(x, y, boxWidth, boxHeight)
        doc.setFont("helvetica", "bold")
        doc.setFontSize(10)
        const textLines = doc.splitTextToSize(box, 60)
        const lines = textLines.slice(0, 7)
        if (textLines.length > 7) {
          const lastLineIndex = 6 // Index der letzten Zeile (0-basiert)
          const lastLine = lines[lastLineIndex]
          const words = lastLine.split(" ")
          if (words.length > 1) {
            words[words.length - 1] = "..."
            lines[lastLineIndex] = words.join(" ")
          } else {
            lines[lastLineIndex] = "..."
          }
        }

        lines.forEach((line, i) => {
          doc.text(line, x + 5, y + 10 + i * 5)
        })
      })

      doc.addPage() // Neue Seite für die Rückseiten

      const boxesBack = pageVocs.map((voc) => {
        let backContent = `${voc.uebersetzung}\n${voc.beispielsatzuebersetzung}`
        // let backContent = `${voc.id}\n${voc.uebersetzung}\n${voc.beispielsatzuebersetzung}`
        return backContent
      })

      boxesBack.forEach((box, index) => {
        const x = (index % 3) * boxWidth
        const y = Math.floor(index / 3) * boxHeight

        const reverseIndex = index % 3 === 0 ? index + 2 : index % 3 === 2 ? index - 2 : index
        const reverseBox = boxesBack[reverseIndex]

        doc.setPage(page * 2 + 2) // Wechsel zur nächsten Seite
        doc.rect(x, y, boxWidth, boxHeight)
        doc.setFont("helvetica", "normal")
        doc.setFontSize(10)

        const textLines = doc.splitTextToSize(reverseBox, 60)
        const lines = textLines.slice(0, 7)
        if (textLines.length > 7) {
          const lastLineIndex = 6 // Index der letzten Zeile (0-basiert)
          const lastLine = lines[lastLineIndex]
          const words = lastLine.split(" ")
          if (words.length > 1) {
            words[words.length - 1] = "..."
            lines[lastLineIndex] = words.join(" ")
          } else {
            lines[lastLineIndex] = "..."
          }
        }

        lines.forEach((line, i) => {
          doc.text(line, x + 5, y + 10 + i * 5)
        })
      })

      if (page < totalPages - 1) {
        doc.addPage() // Zusätzliche Seite für den nächsten Durchlauf
      }
    }

    doc.save("VksKlein.pdf")
  }

  return (
    <div>
      <button onClick={generatePDF}>PDF generieren</button>
    </div>
  )
}

export default VksKlein
